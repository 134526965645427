import SmoothScroll from 'smooth-scroll';
import { observeElement, getWidget } from '@uvocore-front/utils';
import './style.scss';

/**
 *  firstTimeOfferWidget Observer
 */

const widgetOptions = {
  container: '#firstTimeOffer',
  customText: {
    step1: {
      inputPlaceholder: 'example@mail.com',
      errorContent:
        '<p>Oops, this offer is for new clients only.</p><p>P.S. Whenever you need a great essay done fast, <a href="/order.html">we are here</a> to help.</p>',
    },
  },
  stepsCallback: step => {
    switch (step) {
      case 1:
        ga('send', 'event', {
          eventCategory: 'new-customer-discount',
          eventAction: 'discount-popup-landing',
          eventLabel: 'get discount click',
        });
        break;
      case 2:
        ga('send', 'event', {
          eventCategory: 'new-customer-discount',
          eventAction: 'discount-popup-landing',
          eventLabel: 'confirm click',
        });
        break;
      case 3:
        ga('send', 'event', {
          eventCategory: 'new-customer-discount',
          eventAction: 'discount-popup-landing',
          eventLabel: 'order with discount click',
        });
        break;
      case 4:
        ga('send', 'event', {
          eventCategory: 'new-customer-discount',
          eventAction: 'discount-popup-landing',
          eventLabel: 'not a new client',
        });
        break;
      default:
        break;
    }
  },
};

if (document.querySelector('[data-offer-widget]')) {
  observeElement({
    element: '[data-offer-widget]',
    marginValue: 200,
    callback: () => {
      getWidget({
        widgetLink: '/assets/js/firstTimeOffer.min.js',
        loader: true,
        insertLoaderTo: '[data-offer-widget]',
        loaderPosition: 'center',
        fn: () => {
          if (document.querySelector('[data-partner-offer]')) {
            widgetOptions.segment = '1 free page (partner offer)';
          }

          window.firstTimeOfferWidget(widgetOptions);
        },
      });
    },
  });
}

new SmoothScroll('.discount_form', {
  offset: 200,
  updateURL: false,
});

const scrollStopHanlder = () => {
  document.getElementById('user-email').focus();
};

document.addEventListener('scrollStop', scrollStopHanlder, false);
