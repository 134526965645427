/* Shim for deprecated uvoAutocomplete. Only modifies html layout for compatibility */
$.fn.uvoAutocomplete = $.fn.uvoAutocomplete || function() {
  return this.each(function(_, el) {
    if (el.uvoAutocompleteShimInitialized) return;
    el.uvoAutocompleteShimInitialized = true;
    var $el = $(el);
    var $wrapper = $('<div class="uvo_pref-uvoAutocomplete-wrapper" />');
    var $icon = $('<span class="ui-icon ui-icon-triangle-1-s" style="position: absolute; cursor: pointer; right: 10px; top: 0px;" />');
    $el.wrap($wrapper);
    $el.after($icon);
    $el.addClass('uvo_pref-uvoAutocomplete-input');
    $el.css({ appearance: 'none', '::-ms-expand': 'none' });
    $icon.css({ pointerEvents: 'none' });
  })
};


/* ==========================================================================
   2. Custom JS for iwantessay.net
   ========================================================================== */

$(function() {
	//add class "selected" for item select
	if ($.fn.uvoAutocomplete) {
		$('.place-your-order select').uvoAutocomplete({
			sortBy: 'none',
			onOpen: function(e) {
				var lebelEl = this.label;
				$(this._listElement.children()).each(function() {
					if ($(this).text() == lebelEl) {
						$(this).addClass('selected')
					}
				});
			}
		});
	}
	//count pages
	$.fn.counts = function(options) {
			//defaultOptions
			options = $.extend({
				minusClass: 'pageskol-left',
				minimum: 1
			}, options);
			var inputCol = $(this);
			if ($(inputCol).val() <= options.minimum) {
				$('span[class=' + options.minusClass + ']').css({
					'opacity': '0.5',
					'cursor': 'default'
				});
			} else {
				$('span[class=' + options.minusClass + ']').css({
					'opacity': '1',
					'cursor': 'pointer'
				});
			}
			$(inputCol).parent().find('span').click(function() {
				if ($(this).hasClass(options.minusClass)) {
					if ($(inputCol).val() >= options.minimum) {
						var count = parseInt($(inputCol).val()) - 1;
						count = count < options.minimum ? options.minimum : count;
					} else {
						$(inputCol).val(options.minimum - 1);
						var count = parseInt($(inputCol).val()) - 1;
						count = count < options.minimum ? options.minimum : count;
					}
					if ($(inputCol).val() <= (options.minimum + 1)) {
						$('span[class=' + options.minusClass + ']').css({
							'opacity': '0.5',
							'cursor': 'default'
						});
					}
				} else {
					if ($(inputCol).val() >= options.minimum) {
						var count = parseInt($(inputCol).val()) + 1;
					} else {
						$(inputCol).val(options.minimum - 1);
						var count = parseInt($(inputCol).val()) + 1;
					}
					if ($(inputCol).val() > (options.minimum - 1)) {
						$('span[class=' + options.minusClass + ']').css({
							'opacity': '1',
							'cursor': 'pointer'
						})
					}
				}
				$(inputCol).val(count);
				$(inputCol).change();
				$(this).parent().next().find('span').html(275 * count);
				return false;
			});
			$(this).change(function(e) {
				var count = parseInt($(this).val())
				if (isNaN(count)) {
					count = 1;
					$(this).val(1);
				}
				$(this).parent().next().find('span').html(275 * count);
			});
			$(this).focusout(function(e) {
				var count = parseInt($(this).val())
				if (isNaN(count)) {
					count = 1;
					$(this).val(1);
				}
				$(this).parent().next().find('span').html(275 * count);
			});
			$(this).keydown(function(e) {
				if (e.which != 8 && e.which != 0 && (e.which < 37 || e.which > 57) && e
					.which != 96 && e.which != 97 && e.which != 98 && e.which != 99 && e.which !=
					100 && e.which != 101 && e.which != 102 && e.which != 103 && e.which !=
					104 && e.which != 105) {
					return false;
				}
				var key, keyChar;
				if (!e) var e = window.e;
				if (e.keyCode) key = e.keyCode;
				else if (e.which) key = e.which;
				if (key == 8) {
					return true;
				}
				if (key == 38 || key == 39 || (e.which < 48 || e.which > 57) && e.which !=
					96 && e.which != 97 && e.which != 98 && e.which != 99 && e.which !=
					100 && e.which != 101 && e.which != 102 && e.which != 103 && e.which !=
					104 && e.which != 105) {
					if (key == 40 || key == 37) {
						if ($(this).val() >= options.minimum) {
							var count = parseInt($(this).val()) - 1;
							count = count < options.minimum ? options.minimum : count;
						} else {
							$(this).val(options.minimum - 1);
							keyChar = String.fromCharCode(key);
							var count = parseInt($(this).val()) + 1;
						}
					} else {
						if ($(this).val() >= options.minimum) {
							var count = parseInt($(this).val()) + 1;
						} else {
							$(this).val(options.minimum - 1);
							var count = parseInt($(this).val()) + 1;
						}
					}
					$(this).val(count);
					$(this).parent().next().find('span').html(275 * count);
					if ($(inputCol).val() <= (options.minimum)) {
						$('span[class=' + options.minusClass + ']').css({
							'opacity': '0.5',
							'cursor': 'default'
						});
					} else {
						$('span[class=' + options.minusClass + ']').css({
							'opacity': '1',
							'cursor': 'pointer'
						})
					}
				}
			});
		}
		//counts
	$('#input-kol').counts();
	//toggle topmenu
	$('.toggle-menu').click(function() {
		$(this).toggleClass('active');
		if ($(this).next().css('display') == 'none') {
			$(this).next().show();
		} else {
			$(this).next().hide();
		}
		return false;
	});

	$('.sub_menu').siblings('a').click(function(ev) {
		ev.preventDefault();
		$(this).next().fadeToggle(300);
	});

	$('.bg_menu.right_edge').click(function() {
		$('#main_menu > ul').fadeToggle(300);
	});

	if ($('.topmenu-line').length > 0) {
		var tmenu = $('.topmenu-line'),
			tmenuTop = $('.topmenu-line').offset().top;
	}
	$(window).scroll(function() {
		//fixed menu
		var scrlTopPos = $(window).scrollTop(),
			windowWidth = parseInt($(window).width()) + 17;
		if (windowWidth >= 968) {
			if (scrlTopPos <= tmenuTop) {
				$(tmenu).removeClass('fixed');
			} else if (scrlTopPos > tmenuTop) {
				$(tmenu).addClass('fixed');
			}
		}
	});

	//password reminder
	//for inquiry page
	$('.show-hide').find('.hidden').hide();
	$('#inq-done-more').click(function() {
		if (!$(this).hasClass('less')) {
			$(this).parents('.show-hide').find('.hidden').slideDown(500);
			$(this).text('Read less').addClass('less');
		} else {
			$(this).parents('.show-hide').find('.hidden').slideUp(500);
			$(this).text('Read more').removeClass('less');
		}
	});
	$('.plagcheckc aside#rightplagpanel').clone().appendTo('.plagcheckc').addClass(
		'rgt-spec-plag').attr('id', 'rightplagpanel_2');

});
$(document).ready(function() {
	/*connect the slider*/
	$(function() {
		$('#iwant_essay').slides({
			preload: true,
			effect: 'slide',
			crossfade: true,
			slideSpeed: 350,
			fadeSpeed: 500,
			play: 0,
			pause: 0,
			hoverPause: true,
			generateNextPrev: true,
			generatePagination: false
		});
	});
});
/*hover effect #slides_4*/
$(function() {
	$('.slide_tab .item p').mouseover(function() {
		var cl = $(this).attr("class");
		$('.item_' + cl).show();
	});
	$('.slide_tab .item p').mouseout(function() {
		var cl = $(this).attr("class");
		$('.item_' + cl).hide();
	});
});
/*scroll #main_menu*/
$(function() {
	if ($('#main_menu').length > 0) {
		var offset = $('#main_menu').offset();
		var topOffset = offset.top;
		var leftOffset = offset.left;
		var marginTop = $('#main_menu').css("marginTop");
		var marginLeft = $('#main_menu').css("marginLeft");
		$(window).scroll(function() {
			var scrollTop = $(window).scrollTop();
			if (scrollTop >= topOffset) {
				$('#main_menu').css({
					marginTop: 0,
					position: 'fixed',
				}).addClass('fixed-menu');
				$('#hidden_bg').css({
					'display': 'block'
				});
			}
			if (scrollTop < topOffset) {
				$('#main_menu').css({
					marginTop: marginTop,
					position: 'relative',
				}).removeClass('fixed-menu');
				$('#hidden_bg').css({
					'display': 'none'
				});
			}
		});
	}
});

function item_3() {
	$('#item_3').animate({
		'top': '0',
		'opacity': '1'
	}, 400)
}

function item_2() {
	$('#item_2').animate({
		'top': '0',
		'opacity': '1'
	}, 500)
}

function item_1() {
	$('#item_1').animate({
		'top': '-5',
		'opacity': '1'
	}, 700)
}

function logo_404() {
		$('#logo_404').animate({
			'top': '-10',
			'opacity': '1'
		}, 800)
	}
/**
 * EXAMPLES
 *
 */
jQuery(function() {
	jQuery("#toggleall").click(function() {
		jQuery(".exampl_ul li div.exampl_reply").slideToggle(999);
		jQuery(".exampl_ul li").toggleClass("hidden", 100);
	});
	jQuery(".exampl_ul li").toggleClass("hidden", 100);
	jQuery(".exampl_ul li > a").click(function() {
		jQuery(this).next().slideToggle(999);
		jQuery(this).parent().toggleClass("hidden", 100);
		return false;
	});
	if (window.location.href.indexOf("#") != -1) {
		var hash = window.location.hash; //Puts hash in variable, and removes the # character
		if (hash == '#chargeback') {
			jQuery.scrollTo('#chargeback', 1000, {
				margin: true
			});
			jQuery("#chargeback p").slideToggle(333);
			jQuery("#chargeback").toggleClass("hidden", 100);
		};
	}
	$("#target_1").click(function() {
		$("#this_1").click();
	});
	$("#target_2").click(function() {
		$("#this_2").click();
	});
	$("#target_3").click(function() {
		$("#this_3").click();
	});
});

function validateEmail(email) {
	var re =
		/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email);
}


document.addEventListener('DOMContentLoaded', () => {
  const subscribeBtn = document.querySelector('#sub_submit');

  if (!subscribeBtn) return;

  subscribeBtn.addEventListener('click', () => {
    const userEmails = $('#s').val();
    if (validateEmail(userEmails)) {
      $.post('/home/subscribe/', {
        email_subscription: userEmails
      }, function(data) {
        $('.email_sub').html('<div class="text_right">' + data + '</div>');
      });
    } else {
      alert("Email is incorrect!");
    }
  });
});



// YouTube Embed Optimized
$(function() {
	$(".youtube").each(function() {
		// Based on the YouTube ID, we can easily find the thumbnail image
		$(this).css('background-image', 'url(https://i.ytimg.com/vi/' + this.id + '/sddefault.jpg)');

		// Overlay the Play icon to make it look like a video player
		$(this).append($('<div/>', {'class': 'play'}));

		$(document).delegate('#'+this.id, 'click', function() {
			// Create an iFrame with autoplay set to true
			var iframe_url = "https://www.youtube.com/embed/" + this.id + "?autoplay=1&autohide=1";
			if ($(this).data('params')) iframe_url+='&'+$(this).data('params');

			// The height and width of the iFrame should be the same as parent
			var iframe = $('<iframe/>', {'frameborder': '0', 'src': iframe_url, 'width': $(this).width(), 'height': $(this).height() })

			// Replace the YouTube thumbnail with YouTube HTML5 Player
			$(this).replaceWith(iframe);
		});
	});
});

$.getScript('/assets/js/calc.js', function() {
  if ($('[data-calc-short]').length > 0) {
    new Calc({
      ID: '42',
      calculatorType: 'ShortCalculator',
      format: 'json',
      options: {
        deafault: {
          deadlinesDefault: [111257, 111293, 111343, 111397, 111445],
          pagesDefault: 1,
        },
      }
    });
  }

  if ($('[data-calc-mini]').length > 0) {
    new Calc({
      ID: '42',
      calculatorType: 'DefaultCalculator',
      format: 'html',
      options: {
        deafault: {
          deadlinesDefault: [111257, 111293, 111343, 111397, 111445],
          pagesDefault: 1,
        },
      }
    });
  }
});

(function(window, document) {
  function init() {
    window.NotifyWidget({
      siteId: '42',
      container: '.NotifyWidget-banner'
    });
  }

  if (typeof window.NotifyWidget !== 'undefined') {
    init();
  } else {
    const script = document.createElement('script');
    script.src = '/assets/js/notifyWidget.min.js';
    script.async = true;
    script.onload = function () {
      init();
    };
    document.getElementsByTagName('head')[0].appendChild(script);
  }
})(window, document);

(function toggleListInit() {
  if (!document.querySelector('[data-page="frequently_asked_questions"]') && !document.querySelector('[data-page="money_back_guarantee"]')) return false;

  function toggle_list() {
    $('.toggle-list .toggle-content').addClass('hide');
    $(document).on('click', '.toggle-list .toggle-link', function(){
      if($(this).next().hasClass('hide')){ $(this).next().slideDown(500); }else{ $(this).next().slideUp(500); }
      $(this).parent().toggleClass('active');
      $(this).next().toggleClass('hide');
      return false;
    });
  }

  window.addEventListener('contentRendered', toggle_list);
})();
